let baseUrl;
let apiDomain;
let psyUrl
switch (process.env.REACT_APP_RUNNING_ENV) {
  case "production":
    apiDomain = "https://m2b.api.starhome.cc";
    baseUrl = "https://m2b.api.starhome.cc/api/v1.0";
    psyUrl = 'https://m2b.api.starhome.cc';

    break;
   case "test":
    apiDomain = "https://m2b.api.starhome.cc";
    baseUrl = "https://m2b.api.starhome.cc/api/v1.0";
    psyUrl = 'https://m2b.api.starhome.cc';

    break;

    default:

    // apiDomain = "http://192.168.0.33:9992/index.php";
    apiDomain = "http://192.168.0.15:81";
    baseUrl = "/api";
    psyUrl = 'https://m2b.api.starhome.cc';
    break;

}

const netWorkConfig = {
  baseUrl,
  apiDomain,
  psyUrl,
  timeout: 30 * 1000,
};

export default netWorkConfig;
